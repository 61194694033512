import s from "../assets/scss/kvkk.module.scss"

import React, { useEffect, useRef } from "react"

import { gsap } from "../utils/gsap"

export default function CookiePolicy() {
  const contentRef = useRef(null)

  useEffect(() => {
    gsap.fromTo(
      contentRef.current,
      { autoAlpha: 0, yPercent: 1 },
      {
        autoAlpha: 1,
        yPercent: 0,
        duration: 0.3,
        delay: 0.3,
      }
    )
  }, [])

  return (
    <div className={s.content} ref={contentRef}>
      <h1 className={s.titleL}>ÇEREZ ("COOKIE") POLİTİKASI</h1>
      <p className={s.text}>
        İnternet sitemizden en verimli şekilde faydalanabilmeniz ve kullanıcı deneyiminizi geliştirebilmek için Cookie
        kullanıyoruz. Cookie kullanılmasını tercih etmezseniz tarayıcınızın ayarlarından Cookie'leri silebilir ya da
        engelleyebilirsiniz. Ancak bunun internet sitemizi kullanımınızı etkileyebileceğini hatırlatmak isteriz.
        Tarayıcınızdan Cookie ayarlarınızı değiştirmediğiniz sürece bu sitede çerez kullanımını kabul ettiğinizi
        varsayacağız.
        {/* Toplanan verilerle ilgili düzenlemelere internet sitemizde yer alan{" "}
        <a href="https://wamturkey.com/gizlilik-politikasi/">Gizlilik Politikası</a>'ndan ulaşabilirsiniz. */}
      </p>
      <h2 className={s.titleM}>Çerez Nedir ve Neden Kullanılmaktadır?</h2>
      <p className={s.text}>
        Çerezler, ziyaret ettiğiniz internet siteleri tarafından tarayıcılar aracılığıyla cihazınıza veya ağ sunucusuna
        depolanan küçük metin dosyalarıdır.
      </p>
      <p className={s.text}>İnternet Sitemizde çerez kullanılmasının başlıca amaçları aşağıda sıralanmaktadır:</p>
      <ul className={s.bulletList}>
        <li>
          <p className={s.text}>
            İnternet sitesinin işlevselliğini ve performansını arttırmak yoluyla sizlere sunulan hizmetleri geliştirmek,
          </p>
        </li>
        <li>
          <p className={s.text}>
            İnternet Sitesini iyileştirmek ve İnternet Sitesi üzerinden yeni özellikler sunmak ve sunulan özellikleri
            sizlerin tercihlerine göre kişiselleştirmek;
          </p>
        </li>
        <li>
          <p className={s.text}>
            İnternet Sitesinin, sizin ve Şirketimizin hukuki ve ticari güvenliğinin teminini sağlamak.
          </p>
        </li>
      </ul>
      <h2 className={s.titleM}>İnternet Sitemizde Kullanılan Çerez Türleri</h2>
      <h3 className={s.titleS}>Oturum Çerezleri (Session Cookies)</h3>
      <p className={s.text}>
        Oturum çerezleri ziyaretçilerimizin İnternet Sitesini ziyaretleri süresince kullanılan, tarayıcı kapatıldıktan
        sonra silinen geçici çerezlerdir.
      </p>
      <p className={s.text}>
        Bu tür çerezlerin kullanılmasının temel amacı ziyaretiniz süresince İnternet Sitesinin düzgün bir biçimde
        çalışmasının teminini sağlamaktır.
      </p>
      <p className={s.text}>Örneğin; birden fazla sayfadan oluşan çevrimiçi formları doldurmanızın sağlanmaktadır.</p>
      <h3 className={s.titleS}>Kalıcı Çerezler (Persistent Cookies)</h3>
      <p className={s.text}>
        Kalıcı çerezler İnternet Sitesinin işlevselliğini artırmak, ziyaretçilerimize daha hızlı ve iyi bir hizmet
        sunmak amacıyla kullanılan çerez türleridir.
      </p>
      <p className={s.text}>
        Bu tür çerezler tercihlerinizi hatırlamak için kullanılır ve tarayıcılar vasıtasıyla cihazınızda depolanır.
      </p>
      <p className={s.text}>
        Kalıcı çerezlerin bazı türleri; İnternet Sitesini kullanım amacınız gibi hususlar göz önünde bulundurarak
        sizlere özel öneriler sunulması için kullanılabilmektedir.
      </p>
      <p className={s.text}>
        Kalıcı çerezler sayesinde İnternet Sitemizi aynı cihazla tekrardan ziyaret etmeniz durumunda, cihazınızda
        İnternet Sitemiz tarafından oluşturulmuş bir çerez olup olmadığı kontrol edilir ve var ise, sizin siteyi daha
        önce ziyaret ettiğiniz anlaşılır ve size iletilecek içerik bu doğrultuda belirlenir ve böylelikle sizlere daha
        iyi bir hizmet sunulur.
      </p>
      <h3 className={s.titleS}>Otantikasyon Çerezleri (Authentication Cookies)</h3>
      <p className={s.text}>
        Ziyaretçiler, şifrelerini kullanarak internet sitesine giriş yapmaları durumunda, bu tür çerezler ile,
        ziyaretçinin internet sitesinde ziyaret ettiği her bir sayfada site kullanıcısı olduğu belirlenerek,
        kullanıcının her sayfada şifresini yeniden girmesi önlenir.
      </p>
      <h3 className={s.titleS}>Analitik Çerezler (Analytical Cookies)</h3>
      <p className={s.text}>
        Analitik çerezler ile internet sitesini ziyaret edenlerin sayıları, internet sitesinde görüntülenen sayfaların
        tespiti, internet sitesi ziyaret saatleri, internet sitesi sayfaları kaydırma hareketleri gibi analitik
        sonuçların üretimini sağlayan çerezlerdir.
      </p>
    </div>
  )
}
