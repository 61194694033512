import i18n from "i18next"
import { initReactI18next } from "react-i18next"
import LanguageDetector from "i18next-browser-languagedetector"

i18n
  // detect user language
  // learn more: https://github.com/i18next/i18next-browser-languageDetector
  .use(LanguageDetector)
  // pass the i18n instance to react-i18next.
  .use(initReactI18next)
  // init i18next
  // for all options read: https://www.i18next.com/overview/configuration-options
  .init({
    debug: true,
    fallbackLng: "TR",
    lng: "TR",
    interpolation: {
      escapeValue: false, // not needed for react as it escapes by default
    },
    resources: {
      EN: {
        translation: {
          routes: {
            home: "/home",
            executives: "/executives",
            boardMembers: "/board-members",
            contact: "/contact",
            kvkk: "/pdpa",
            cookiePolicy: "/cookie-policy",
            approvalForm: "/commercial-electronic-message-approval-form",
          },
          header: {
            link: {
              about: {
                title: "About",
                submenu: {
                  boardMembers: "Board Members",
                  executives: "Executives",
                  license: "License",
                },
              },
              financialInfo: {
                title: "Financial Reports",
                submenu: {
                  mainContract: {
                    title: "Articles of Association",
                  },
                  files: { title: "General Assembly" },
                  reports: {
                    title: "Activity Reports",
                    sub: {
                      s1: {
                        ui: "2022 YE",
                      },
                      s2: {
                        ui: "2023 1H",
                      },
                      s3: {
                        ui: "2023 YE",
                      },
                      s4: {
                        ui: "2024 1H",
                      },
                    },
                  },
                  audit: {
                    title: "Independent Audit Reports",
                    sub: {
                      s1: {
                        ui: "2022 YE",
                      },
                      s2: {
                        ui: "2023 1H",
                      },
                      s3: {
                        ui: "2023 YE",
                      },
                      s4: {
                        ui: "2024 1H",
                      },
                    },
                  },
                },
              },
              contact: "Contact",
            },
            contactUsBtnText: "Contact Us",
          },
          home: {
            hero: {
              title: {
                part1: "We",
                part2: "invest in ",
                part3: "tomorrow's",
                part4: "leaders,",
                part5: "today",
              },
              text: {
                part1:
                  "WAM Asset & Portfolio manages venture capital investment funds and real estate investment funds in Turkey.",
                part2:
                  "We serve institutional investors, and our investments are designed to preserve and grow our investors' capital across market cycles.",
              },
            },
            aboutUs: {
              title: "About Us",
              ourValues: {
                title: { part1: "Our Values", part2: "Mission" },
                text: {
                  part1:
                    "Through our family office roots and entrepreneurial heritage, our approach to alternative investments is based on a culture of strong partnerships, open communication, innovation and relentless focus on growth.",
                  part2:
                    "WAM invests only for the long term to build resilient businesses with strong returns and sustained economic growth. We support skilled management teams in strategy development and implementing industry best practices and deploy long term growth capital to help companies in institution building and create value from growth.",
                },
              },
              ourTeam: {
                title: "Our Team",
                text: "Our senior team averages more than 25 years of experience in principal investing, asset & portfolio management, operational transformation and financing with an extensive business network supported by the Founders' strong reputation. This allows us to draw on deep industry expertise to develop businesses with compelling secular themes and to drive operational improvements that accelerate income growth.",
                btnText1: "Board Members",
                btnText2: "Executives",
              },
              mobileExpandBtnText: {
                expand: "Read More",
                shrink: "Hide",
              },
            },
            ourFunds: {
              title: "Our Funds",
              propertyFunds: {
                title: {
                  part1: "Real Estate",
                  part2: "Investment Funds",
                },
                // text: "You will be able to see our funds here very soon.",
                btns: {
                  b1: {
                    ui: "First Real Estate Investment Fund",
                  },
                  b2: {
                    ui: "Second Real Estate Investment Fund",
                  },
                },
                accordionItems: {
                  b1: {
                    ui: "Current Internal Regulation",
                  },
                  b2: {
                    ui: "Current Export Document",
                  },
                  b3: {
                    ui: "Current Internal Regulation",
                  },
                  b4: {
                    ui: "Current Export Document",
                  },
                },
              },
              investmentFunds: {
                title: {
                  part1: "Venture Capital",
                  part2: "Investment Funds",
                },
                // text: "You will be able to see our funds here very soon.",
                btns: {
                  b1: {
                    ui: "First Venture Capital Investment Fund",
                  },
                  b2: {
                    ui: "Second Venture Capital Investment Fund",
                  },
                },
                accordionItems: {
                  b1: {
                    ui: "Current Internal Regulation",
                  },
                  b2: {
                    ui: "Current Export Document",
                  },
                  b3: {
                    ui: "Current Internal Regulation",
                  },
                  b4: {
                    ui: "Current Export Document",
                  },
                },
              },
            },
          },
          executives: {
            title: "Executives",
            text: "Our senior team averages more than 25 years of experience in principal investing, asset & portfolio management, operational transformation and financing with an extensive business network supported by the Founders' strong reputation. This allows us to draw on deep industry expertise to develop businesses with compelling secular themes and to drive operational improvements that accelerate income growth.",
          },
          boardMembers: {
            title: {
              part1: "Board",
              part2: "Members",
            },
            text: "Our senior team averages more than 25 years of experience in principal investing, asset & portfolio management, operational transformation and financing with an extensive business network supported by the Founders' strong reputation",
          },
          executiveInfo: {
            e1: {
              name: "Can İkinci",
              role: "Chairman and CEO",
              detail: {
                part1:
                  "Can İkinci was a Partner at Actera Group, Turkey's largest private equity group with $3.3bn of assets under management, between 2012-2022. At Actera Group, he served as a Member of Board at Kamil Koç, UN Ro-Ro, Joker, and Soobe. During this time, he also spearheaded the growth and institution building at two portfolio companies, as the CEO of Gratis for one year, and CEO of Mars Athletic (MACFit) for 2 years. Prior to joining Actera, Mr. Ikinci was the CEO of Alshaya Group Turkey (one of the leading retail groups in the country with over 2,500 employees) where he led the successful growth of numerous retail businesses (including Starbucks Coffee, Victoria's Secret, The Body Shop) in Turkey.",
                part2:
                  "Prior to Alshaya Group, Mr. Ikinci was with McKinsey & Company in New Jersey and London. He started his career at TEFEN Operations Management Consulting in New York.",
                part3:
                  "Mr. Ikinci received a BS in Mechanical Engineering from Bosphorus University in Istanbul, a MS in Industrial Engineering and Operations Research from Columbia University and an MBA from The Wharton School, University of Pennsylvania.",
              },
            },
            e2: {
              name: "Türker Tekten",
              role: "Board Member and CFO",
              detail: {
                part1:
                  "Turker Tekten has been the CFO of Actera Group and served on the firm's Investment and Portfolio Committees between 2007-2021. During his tenure at Actera Group, he served on the Board of Auditors of all Actera portfolio companies and as a Member of the Board of Karnaval Media Group, Dunya Varlik, Tam Faktoring, g2m, UNRoro, Kamil Koc, Standard Profil, Soobe, Barma Gayrimenkul, Talca Gayrimenkul and Vivense.",
                part2:
                  "Prior to joining Actera, Mr. Tekten was the CAO and CFO of Morgan Stanley in Turkey and managed the establishment of the Turkish entity Morgan Stanley Menkul Degerler A.S. where he held the position of Acting General Manager. Prior to Morgan Stanley, Mr. Tekten was the CFO & COO of Standard Bank Turkey operations between 2003-2006. Prior to Standard Bank, Mr. Tekten held a number of positions at JP Morgan Chase Turkey including serving as the CFO of the bank and as AVP of Emerging Markets Turkey Structured Products Desk. Mr. Tekten started his career at Turkiye Is Bankasi, a leading Turkish commercial bank, as an Assistant Internal Auditor in 1992.",
                part3:
                  "Mr. Tekten received a BS in Industrial Engineering from Bilkent University and a BA in Philosophy from Istanbul University.",
                part4: "Mr.Tekten holds Capital Market Activities Level 3 License and Derivative Instruments License.",
              },
            },
            e3: {
              name: "Şeyda Oltulu",
              role: "Board Member",
            },
            e4: {
              name: "Hurşit Şen",
              role: "Board Member",
            },
          },
          contact: {
            title: {
              part1: "Contact Us",
              part2: "",
            },
            form: {
              label: {
                name: "Name Surname",
                company: "Company",
                email: "Email Address",
                phone: "Phone Number",
                message: "Message",
                btnText: "Send",
              },
              legalTexts: {
                t1: {
                  text: "I consent to receiving commercial electronic messages from WAM Portfolio to my electronic communication addresses.",
                  link: "/commercial-electronic-message-approval-form",
                },
                t2: {
                  text: "I have read the relevant Information Notice pursuant to the Personal Data Protection Act.",
                  link: "/pdpa",
                },
                t3: {
                  text: "I have read, accept and give explicit consent to the information text regarding the processing and transfer of my personal data.",
                  link: "/pdpa",
                },
              },
              success: "Form submitted successfully.",
            },
            contactInfo: {
              companyName: "WAM GAYRİMENKUL VE GİRİŞİM SERMAYESİ PORTFÖY YÖNETİMİ A.Ş.",
              address: "Address",
              phone: "Tel",
              fax: "Fax",
              email: "Email",
            },
          },
          footer: {
            link: {
              contact: "Contact",
              career: "Career",
              infoForm: "Information Form",
              emergencyPlan: "Disaster Recovery Plan",
              mkkPortal: "MKK Company Information Portal",
              kap: "Public Disclosure Platform",
              informationSociety: "Transparency in Coverage",
            },
            copyright: {
              rights: "All Rights Reserved",
              kvkk: "Information Security Policy",
              cookiePolicy: "Cookie Policy",
            },
          },
          userAgreement: {
            text: "WAM Portfolio values your privacy. By browsing this page and/or using this site, you agree to the Terms of Use agreement.",
          },
        },
      },
      TR: {
        translation: {
          routes: {
            home: "/anasayfa",
            executives: "/yoneticiler",
            boardMembers: "/yonetim-kurulu",
            contact: "/iletisim",
            kvkk: "/kvkk",
            cookiePolicy: "/cerez-politikasi",
            approvalForm: "/ticari-elektronik-ileti-onay-formu",
          },
          header: {
            link: {
              about: {
                title: "Hakkımızda",
                submenu: {
                  boardMembers: "Yönetim Kurulu",
                  executives: "Yöneticiler",
                  license: "Yetki Belgesi",
                },
              },
              financialInfo: {
                title: "Finansal Raporlar",
                submenu: {
                  mainContract: {
                    title: "Ana Sözleşme",
                  },
                  files: { title: "Genel Kurul Dosyaları" },
                  reports: {
                    title: "Faaliyet Raporları",
                    sub: {
                      s1: {
                        ui: "2022 Sene Sonu",
                      },
                      s2: {
                        ui: "2023 Ara Dönem",
                      },
                      s3: {
                        ui: "2023 Sene Sonu",
                      },
                      s4: {
                        ui: "2024 Ara Dönem",
                      },
                    },
                  },
                  audit: {
                    title: "Bağımsız Denetçi Raporları",
                    sub: {
                      s1: {
                        ui: "2022 Sene Sonu",
                      },
                      s2: {
                        ui: "2023 Ara Dönem",
                      },
                      s3: {
                        ui: "2023 Sene Sonu",
                      },
                      s4: {
                        ui: "2024 Ara Dönem",
                      },
                    },
                  },
                },
              },
              contact: "İletişim",
            },
            contactUsBtnText: "Bizimle İletişime Geçin",
          },
          home: {
            hero: {
              title: {
                part1: "Yarının",
                part2: "liderlerine",
                part3: "bugünden",
                part4: "yatırım",
                part5: "yapıyoruz",
              },
              text: {
                part1:
                  "WAM Gayrimenkul ve Girişim Sermayesi Portföy Yönetimi A.Ş., yönettiği girişim sermayesi yatırım fonları (GSYF) ve gayrimenkul yatırım fonları (GYF) aracılığıyla kurumsal yatırımcılara profesyonel portföy yönetim hizmeti vermektedir.",
                part2:
                  "Yatırımlarımızın temel odağı, yatırımcılarımızın varlıklarını her türlü piyasa koşulunda korumak ve büyütmektir.",
              },
            },
            aboutUs: {
              title: "Hakkımızda",
              ourValues: {
                title: { part1: "Değerlerimiz", part2: "Misyonumuz" },
                text: {
                  part1:
                    "Kökleri aile yatırımlarından ve girişimci ruhtan beslenen yatırım yaklaşımımız, kuvvetli iş birlikleri, açık iletişim, inovasyon ve büyüme odağını merkeze alan bir kültür yaratmıştır.",
                  part2:
                    "WAM yalnızca uzun vadeli yatırımlar yaparak, zamana dayanıklı, yüksek getirili ve sürdürülebilir ekonomik büyümesi olan şirket ve gayrimenkul varlıklardan değer üretir. Bunu yaparken sıra dışı yöneticilerle ve esnek sermaye gücüyle, yatırımların hem kurumsallaşma süreçlerine hem büyümelerinin ivmelenmesine destek olur.",
                },
              },
              ourTeam: {
                title: "Ekibimiz",
                text: "Üst düzey yönetici ekibimiz ortalama 25 yıldan fazla şirket satın alma, varlık yönetimi, portföy yönetimi ve finansman tecrübesine sahiptir. Bu sayede derin endüstri bilgilerini, kuvvetli temellere sahip şirketlerin gelişimi için kullanırlar.",
                btnText1: "Yönetim Kurulu",
                btnText2: "Yöneticiler",
              },
              mobileExpandBtnText: {
                expand: "Devamını Oku",
                shrink: "Gizle",
              },
            },
            ourFunds: {
              title: "Fonlarımız",
              propertyFunds: {
                title: { part1: "Gayrimenkul", part2: "Yatırım Fonlarımız" },
                // text: "Kuruluş aşamasında olan fonlarımızı çok yakında burada görebileceksiniz.",
                btns: {
                  b1: {
                    ui: "Birinci Gayrimenkul Yatırım Fonu",
                  },
                  b2: {
                    ui: "İkinci Gayrimenkul Yatırım Fonu",
                  },
                },
                accordionItems: {
                  b1: {
                    ui: "Güncel İç Tüzük",
                  },
                  b2: {
                    ui: "Güncel İhraç Belgesi",
                  },
                  b3: {
                    ui: "Güncel İç Tüzük",
                  },
                  b4: {
                    ui: "Güncel İhraç Belgesi",
                  },
                },
              },
              investmentFunds: {
                title: {
                  part1: "Girişim Sermayesi",
                  part2: "Yatırım Fonlarımız",
                },
                // text: "Kuruluş aşamasında olan fonlarımızı çok yakında burada görebileceksiniz.",
                btns: {
                  b1: {
                    ui: "Birinci Girişim Sermayesi Yatırım Fonu",
                  },
                  b2: {
                    ui: "İkinci Girişim Sermayesi Yatırım Fonu",
                  },
                },
                accordionItems: {
                  b1: {
                    ui: "Güncel İç Tüzük",
                  },
                  b2: {
                    ui: "Güncel İhraç Belgesi",
                  },
                  b3: {
                    ui: "Güncel İç Tüzük",
                  },
                  b4: {
                    ui: "Güncel İhraç Belgesi",
                  },
                },
              },
            },
          },
          executives: {
            title: "Yöneticilerimiz",
            text: "Üst düzey yönetici ekibimiz ortalama 25 yıldan fazla şirket satın alma, varlık yönetimi, portföy yönetimi ve finansman tecrübesine sahiptir. Bu sayede derin endüstri bilgilerini, kuvvetli temellere sahip şirketlerin gelişimi için kullanırlar.",
          },
          boardMembers: {
            title: {
              part1: "Yönetim",
              part2: "Kurulu",
            },
            text: "Üst düzey yönetim kurulumuz ortalama 25 yıldan fazla şirket satın alma, varlık yönetimi, portföy yönetimi ve finansman tecrübesine sahiptir.",
          },
          executiveInfo: {
            e1: {
              name: "Can İkinci",
              role: "Yönetim Kurulu Başkanı ve Genel Müdür",
              detail: {
                part1:
                  "2012-2022 yılları arasında 3.3 milyar Amerikan Dolarını aşan büyüklükteki yatırım fonlarına danışmanlık yapan Türkiye'deki yatırımlara odaklanmış en büyük özel sermaye şirketi Actera Group'un Ortak Yöneticisi (Partner) olarak görev yapmıştır. Actera Group bünyesinde Kamil Koç, U.N.RO-RO, Joker ve Soobe firmalarının Yönetim Kurulu Üyeliğini yürütmüştür. Ayrıca bu dönem içinde Actera Group'un çoğunluk hissedarı olduğu Gratis'te 1 yıl ve Mars Sportif'te (MACFit) 2 yıl süreyle tam zamanlı CEO (Genel Müdür) olarak bu şirketlerin hızlı büyümesi ve kurumsallaşmasına önderlik etmiştir.",
                part2:
                  "Actera Group'ta fon yöneticiliği ve CEO rollerinden önce, 2006-2012 yıllları arasında Shaya Group'un Türkiye Genel Müdürü olarak Starbucks Coffee, The Body Shop, Victoria's Secret gibi önemli uluslararası şirketlerin başarılı büyüme süreçlerini yönetmiştir. Kendisi, Starbucks ve Shaya Group'a liderlik etmeden öncesindeki 2 yıl boyunca McKinsey & Company'nin Londra ofisinde perakende, bankacılık, hızlı tüketim sektörlerinde çeşitli uluslararası firmalara danışmanlık hizmeti vermiştir. Bunun öncesinde 1999-2003 yılları arasında Tefen Operations Consulting'in New York ofisinde birçok sektördeki uluslararası firmalara operasyonel verimlilik konularında destek vermiştir.",
                part3:
                  "Can İkinci'nin Boğaziçi Üniversitesi'nden Makina Mühendisliği lisans, Columbia Üniversitesi'nden Endüstri Mühendisliği yüksek lisans, Wharton Business School'dan Finans ve İşletme yüksek lisans (MBA) dereceleri bulunmaktadır.",
              },
            },
            e2: {
              name: "Türker Tekten",
              role: "Yönetim Kurulu Üyesi ve Genel Müdür Yardımcısı",
              detail: {
                part1:
                  "2007-2021 yılları arasında 3.3 milyar Amerikan Dolarını aşan büyüklükteki yatırım fonlarına danışmanlık yapan Türkiye'deki yatırımlara odaklanmış en büyük özel sermaye şirketi Actera Group 'un Mali İşlerden Sorumlu Üst Düzey Ortak Yöneticisi olarak görev yapmıştır. Bu görevi sırasında Actera bünyesindeki Dünya Varlık Yönetim, Tam Finans, Joker, Standard Profil, Karnaval Media Group, Kamil Koç, g2m, Vivense ve UN Ro-Ro şirketlerinde Yönetim Kurulu Üyeliği ve Denetim Komitesi Üyeliği görevlerini ve Korozo, Mars Sinema Grubu, Mars Sportif ve Çelebi Havacılık şirketlerinde Denetim Komitesi Üyeliği görevlerini yürütmüştür.",
                part2:
                  "Actera'dan önce, 2006-2007 yılları arasında Morgan Stanley Türkiye'de Mali İşler ve Operasyonlardan Sorumlu Üst Düzey Yönetici ve Morgan Stanley Menkul Değerler A.Ş.' de Genel Müdür Vekili görevlerini yürütmüştür. Morgan Stanley 'e katılmadan önce, 2003-2006 yılları arasında Standard Bank Türkiye operasyonlarında Mali İşler ve Operasyonlardan Sorumlu Üst Düzey Yönetici olarak görev yapmıştır. Standard Bank 'dan önce, 1995-2002 yılları arasında JP Morgan Chase Türkiye' de Mali İşlerden Sorumlu Genel Müdür Yardımcılığı ve Yapılandırılmış Ürünler Türkiye Masası Başkan Yardımcılığı da dahil olmak üzere çeşitli görevlerde bulunmuştur.",
                part3: "Kariyerine 1992 senesinde Türkiye İş Bankası'nda Müfettiş Yardımcısı olarak başlamıştır.",
                part4:
                  "Türker Tekten'in Bilkent Üniversitesi'nden Endüstri Mühendisliği lisans ve İstanbul Üniversitesi'nden Felsefe lisans dereceleri bulunmaktadır. Sermaye Piyasası Faaliyetleri Düzey 3 Lisansı ve Türev Araçlar Lisansına sahiptir.",
              },
            },
            e3: {
              name: "Şeyda Oltulu",
              role: "Yönetim Kurulu Üyesi",
            },
            e4: {
              name: "Hurşit Şen",
              role: "Yönetim Kurulu Üyesi",
            },
          },
          contact: {
            title: {
              part1: "Bizimle iletişime",
              part2: "geçin",
            },
            form: {
              label: {
                name: "Ad Soyad",
                company: "Şirket",
                email: "Eposta Adresiniz",
                phone: "Telefon No",
                message: "Mesajınız",
                btnText: "Gönder",
              },
              legalTexts: {
                t1: {
                  text: "WAM Portföy tarafından elektronik iletişim adreslerime ticari elektronik ileti gönderilmesine izin veriyorum.",
                  link: "/ticari-elektronik-ileti-onay-formu",
                },
                t2: {
                  text: "Kişisel Verilerin Korunması Kanunu uyarınca gerçekleştirilen ilgili Bilgilendirme'yi okudum.",
                  link: "/kvkk",
                },
                t3: {
                  text: "Kişisel verilerimin işlenmesine ve aktarılmasına ilişkin aydınlatma metnini okudum, kabul ediyorum ve açık rıza veriyorum.",
                  link: "/kvkk",
                },
              },
              success: "Form başarıyla iletildi.",
            },
            contactInfo: {
              companyName: "WAM GAYRİMENKUL VE GİRİŞİM SERMAYESİ PORTFÖY YÖNETİMİ A.Ş.",
              address: "Adres",
              phone: "Tel",
              fax: "Fax",
              email: "Eposta",
            },
          },
          footer: {
            link: {
              contact: "İletişim",
              career: "Kariyer",
              infoForm: "Sürekli Bilgilendirme Formu",
              emergencyPlan: "Acil Durum Eylem Planı",
              mkkPortal: "MKK Şirket Bilgilendirme Portalı",
              kap: "Kamuyu Aydınlatma Platformu (KAP)",
              informationSociety: "Bilgi Toplumu Hizmetleri",
            },
            copyright: {
              rights: "Tüm Hakları Saklıdır",
              kvkk: "KVKK Aydınlatma Metni",
              cookiePolicy: "Çerez Politikası",
            },
          },
          userAgreement: {
            text: "WAM Portföy gizliliğinize önem veriyor. Bu sayfada gezinerek ve / veya bu siteyi kullanarak Kullanım Koşulları sözleşmesini kabul etmiş olursunuz.",
          },
        },
      },
    },
  })

export default i18n
