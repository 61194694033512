import s from "../assets/scss/kvkk.module.scss"

import React, { useEffect, useRef } from "react"

import { gsap } from "../utils/gsap"

export default function ApprovalForm() {
  const contentRef = useRef(null)

  useEffect(() => {
    gsap.fromTo(
      contentRef.current,
      { autoAlpha: 0, yPercent: 1 },
      {
        autoAlpha: 1,
        yPercent: 0,
        duration: 0.3,
        delay: 0.3,
      }
    )
  }, [])

  return (
    <div className={s.content} ref={contentRef}>
      <h1 className={s.titleL}>TİCARİ ELEKTRONİK İLETİ ONAY FORMU</h1>
      <p className={s.text}>
        6563 Sayılı Elektronik Ticaretin Düzenlenmesi Hakkında Kanun ve Ticari İletişim ve Ticari Elektronik İletiler
        Hakkında yönetmelik uyarınca; telefon, çağrı merkezleri, faks, otomatik arama makineleri, akıllı ses kaydedici
        sistemler, ses ve görüntü içerikli iletiler, ticari elektronik ileti olarak sayılmaktadır.
      </p>
      <p className={s.text}>
        Ticari elektronik iletiler, alıcılara ancak önceden onayları alınmak kaydıyla gönderilebilir. İşbu Onay Formu’nu
        kabul etmekle Wam Portföy Yönetimi A.Ş. tarafından gerçekleştirilecek ve Wam'a ait internet sitesinde ve benzeri
        elektronik ortamlarda gerçekleştirilen ve ticari amaçlarla tarafınıza gönderilen tanıtım, bilgilendirme,
        memnuniyet araştırmaları, anket çalışmaları, kutlama, temenni, etkinliklere ilişkin davetlerin iletilmesine
        ilişkin mesaj ve bilgilendirmelerden haberdar olmayı; işbu faaliyetler kapsamında tarafınızla elektronik posta,
        kısa mesaj, telefon ve benzeri vasıtalarla veri, ses ve görüntü içerikli her türlü ticari elektronik iletinin
        gönderilmesi suretiyle iletişime geçilebileceğini, gerektiğinde söz konusu kayıtların ilgili Bakanlık ile
        paylaşılabileceğini kabul etmektesiniz. Alıcının kendisiyle iletişime geçilmesi amacıyla iletişim bilgilerini
        vermesi hâlinde, temin edilen mal veya hizmetlere ilişkin değişiklik, kullanım ve bakıma yönelik ticari
        elektronik iletiler için ayrıca onay alınmaz.
      </p>
      <p className={s.text}>
        Onay Formu’nun kabul edilmesi, reddetme hakkının kullanılması anına kadar geçerlidir; onay verilmemiş olması
        Şirket’in tarafınıza sağlayacağı hizmetleri etkilemeyecektir.
      </p>
      <p className={s.text}>
        Dilediğiniz zaman, hiçbir gerekçe göstermeksizin bu kullanım şartları kapsamındaki elektronik iletileri almaktan
        vazgeçebilirsiniz. Bu talebinizi Şirketimize çağrı veya iletide yer alan iletişim bilgilerini kullanarak veya
        Şirketimiz bilgilerindeki iletişim adreslerine ücretsiz olarak iletebilirsiniz.
      </p>
      <p className={s.text}>
        Tarafıma Wam Portföy Yönetimi A.Ş. tarafından yukarıda yer alan bilgiler ışığında ticari elektronik ileti
        gönderilmesini okudum, onaylıyorum.
      </p>
    </div>
  )
}
