import s from "../assets/scss/contact.module.scss"

import axios from "axios"
import cx from "classnames"
import React, { useEffect, useLayoutEffect, useState } from "react"
import { useTranslation } from "react-i18next"
import { Link, useLocation, useNavigate } from "react-router-dom"
import i18n from "../i18n/i18n"

import IconArrow from "../components/iconArrow"
import useCursorStore from "../stores/cursorStore"
import useHeaderStore from "../stores/themeStore"

export default function Contact() {
  const setHeaderDark = useHeaderStore((state) => state.setDarkTheme)
  const scaleCursorUp = useCursorStore((state) => state.scaleUp)
  const scaleCursorDown = useCursorStore((state) => state.scaleDown)
  const location = useLocation()

  const { t } = useTranslation()

  const [formSent, setFormSent] = useState(false)
  const [requiredAlert, setRequiredAlert] = useState(false)

  useLayoutEffect(() => {
    setHeaderDark()
  }, [])

  let navigate = useNavigate()
  useEffect(() => {
    if (i18n.language === "TR") {
      navigate("/iletisim")
    } else {
      navigate("/contact")
    }
  }, [i18n.language])

  const [name, setName] = useState(null)
  const [company, setCompany] = useState(null)
  const [email, setEmail] = useState(null)
  const [phone, setPhone] = useState(null)
  const [message, setMessage] = useState(null)

  const [checked1, setChecked1] = useState(false)
  const [checked2, setChecked2] = useState(false)
  const [checked3, setChecked3] = useState(false)

  const handleSubmit = () => {
    const baseUrl = "https://wamportfoy.com/services/messageService.php"
    const config = { headers: { "Content-Type": "multipart/form-data" } }

    if (name && company && email && phone && name) {
      let formData = new FormData()

      formData.append("name", name)
      formData.append("company", company)
      formData.append("email", email)
      formData.append("phone", phone)
      formData.append("message", message)

      axios({
        method: "post",
        url: baseUrl,
        data: formData,
        config,
      })
        .then((res) => {
          console.log(res)
          setFormSent(true)
          window.scrollTo({ top: 0, left: 0, behavior: "smooth" })
        })
        .catch((err) => {
          console.log(err)
          setFormSent(false)
        })
    } else {
      setRequiredAlert(true)
      setTimeout(() => {
        setRequiredAlert(false)
      }, 500)
    }
  }

  return (
    <main className={s.contactWrapper}>
      <section className={s.contact}>
        <h1 className={s.title}>
          {t("contact.title.part1")} <br /> {t("contact.title.part2")}
        </h1>
        <div className={s.bottom}>
          <div className={s.successWrapper}>
            <div className={cx(s.formWrapper, { [s.success]: formSent })}>
              <div className={s.row}>
                <div className={s.inputWrapper}>
                  <label htmlFor="name" className={s.formLabel}>
                    {t("contact.form.label.name")}*
                  </label>
                  <input
                    id="name"
                    type="text"
                    required
                    className={cx(s.formInput, { [s.alert]: requiredAlert })}
                    onChange={(e) => {
                      setName(e.currentTarget.value)
                    }}
                  />
                </div>
                <div className={s.inputWrapper}>
                  <label htmlFor="company" className={s.formLabel}>
                    {t("contact.form.label.company")}*
                  </label>
                  <input
                    id="company"
                    type="text"
                    required
                    className={cx(s.formInput, { [s.alert]: requiredAlert })}
                    onChange={(e) => {
                      setCompany(e.currentTarget.value)
                    }}
                  />
                </div>
              </div>
              <div className={s.row}>
                <div className={s.inputWrapper}>
                  <label htmlFor="email" required className={s.formLabel}>
                    {t("contact.form.label.email")}*
                  </label>
                  <input
                    id="email"
                    type="email"
                    required
                    className={cx(s.formInput, { [s.alert]: requiredAlert })}
                    onChange={(e) => {
                      setEmail(e.currentTarget.value)
                    }}
                  />
                </div>
                <div className={s.inputWrapper}>
                  <label htmlFor="phone" required className={s.formLabel}>
                    {t("contact.form.label.phone")}*
                  </label>
                  <input
                    id="phone"
                    type="text"
                    className={cx(s.formInput, { [s.alert]: requiredAlert })}
                    onChange={(e) => {
                      setPhone(e.currentTarget.value)
                    }}
                  />
                </div>
              </div>
              <div className={s.row}>
                <div className={`${s.inputWrapper} ${s.message}`}>
                  <label className={s.formLabel}>{t("contact.form.label.message")}</label>
                  <input
                    name="message"
                    type="text"
                    className={`${s.formInput} ${s.textarea}`}
                    onChange={(e) => {
                      setMessage(e.currentTarget.value)
                    }}
                    style={{ resize: "none" }}
                  />
                  <button
                    className={cx(s.submitBtn, { [s.enabled]: checked1 && checked2 && checked3 })}
                    onClick={handleSubmit}
                    onMouseEnter={scaleCursorUp}
                    onMouseLeave={scaleCursorDown}
                  >
                    <p className={s.text}>{t("contact.form.label.btnText")}</p>
                    <div className={`${s.arrow} ${s.right}`}>
                      <IconArrow color="#003566" />
                    </div>
                  </button>
                </div>
              </div>
              <div className={cx(s.row, s.legalStart)}>
                <div className={cx(s.confirmation, { [s.enabled]: checked1 })}>
                  <div
                    className={s.checkbox}
                    onClick={() => {
                      setChecked1((prev) => !prev)
                    }}
                  >
                    <div className={s.check}></div>
                  </div>
                  <p className={s.checkText}>
                    <Link className={s.link} to={t("contact.form.legalTexts.t1.link")} state={{ background: location }}>
                      {t("contact.form.legalTexts.t1.text")}
                    </Link>
                  </p>
                </div>
              </div>
              <div className={s.row}>
                <div className={cx(s.confirmation, { [s.enabled]: checked2 })}>
                  <div
                    className={s.checkbox}
                    onClick={() => {
                      setChecked2((prev) => !prev)
                    }}
                  >
                    <div className={s.check}></div>
                  </div>
                  <p className={s.checkText}>
                    <Link className={s.link} to={t("contact.form.legalTexts.t2.link")} state={{ background: location }}>
                      {t("contact.form.legalTexts.t2.text")}
                    </Link>
                  </p>
                </div>
              </div>
              <div className={s.row}>
                <div className={cx(s.confirmation, { [s.enabled]: checked3 })}>
                  <div
                    className={s.checkbox}
                    onClick={() => {
                      setChecked3((prev) => !prev)
                    }}
                  >
                    <div className={s.check}></div>
                  </div>
                  <p className={s.checkText}>
                    <Link className={s.link} to={t("contact.form.legalTexts.t3.link")} state={{ background: location }}>
                      {t("contact.form.legalTexts.t3.text")}
                    </Link>
                  </p>
                </div>
              </div>
              <div className={s.row}>
                <button
                  className={cx(s.submitBtn, s.mobile, {
                    [s.enabled]: checked1 && checked2 && checked3,
                  })}
                  onClick={handleSubmit}
                >
                  <p className={s.text}>{t("contact.form.label.btnText")}</p>
                  <div className={`${s.arrow} ${s.right}`}>
                    <IconArrow color="#003566" />
                  </div>
                </button>
              </div>
            </div>
            <div className={cx(s.successMessage, { [s.success]: formSent })}>
              <p className={s.successText}>{t("contact.form.success")}</p>
            </div>
          </div>
          <div className={s.contactInfo}>
            <h5 className={s.companyName}>{t("contact.contactInfo.companyName")}</h5>
            <div className={s.contactInfoRow}>
              <small className={s.label}>{t("contact.contactInfo.address")}:</small>
              <div className={s.info}>
                Teşvikiye Mah. Teşvikiye Cad. AVM Blok No: 12 İç Kapı No: 108 Şişli / İstanbul
              </div>
            </div>
            <div className={s.contactInfoRow}>
              <small className={s.label}>{t("contact.contactInfo.phone")}:</small>
              <a style={{ userSelect: "auto" }} href="tel:+902123733007" className={s.info}>
                +90 (212) 373 30 07
              </a>
            </div>
            <div className={s.contactInfoRow}>
              <small className={s.label}>{t("contact.contactInfo.fax")}:</small>
              <a style={{ userSelect: "auto" }} href="tel:+902123733035" className={s.info}>
                +90 (212) 373 30 35
              </a>
            </div>
            <div className={s.contactInfoRow}>
              <small className={s.label}>{t("contact.contactInfo.email")}:</small>
              <a href="mailto:info@wamportfoy.com" className={`${s.info} ${s.email}`}>
                info@wamportfoy.com
              </a>
            </div>
          </div>
        </div>
      </section>
    </main>
  )
}
